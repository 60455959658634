import { createSlice } from "@reduxjs/toolkit";
import { AnalyticsInitialState } from "../../../types/redux";
import {
  createAnalyticsGraphAsync,
  createSheetAsync,
  deleteAnalyticsGraphAsync,
  getAllAnalyticsGraphAsync,
  getAnalyticsExcelDataAsync,
  getAnalyticsExcelKeyAsync,
  getSheetsAsync,
  updateAnalyticsGraphAsync,
  updateSheetAsync,
  uploadExcelAsync,
} from "./analyticsAPI";

const initialState: AnalyticsInitialState = {
  loading: false,
  excelHeaderLoading: false,
  excelDataLoading: false,
  postLoading: false,
  excelPostLoading: false,
  updateLoading: false,
  deleteLoading: false,
  error: "",
  sheets: null,
  excelKeys: [],
  excelData: [],
  tabItem: "Create Sheet",
  graphAddDataLoading: false,
  graphUpdateDataLoading: false,
  graphDataLoading: false,
  isGraphDataAdd: false,
  graphData: {
    position: "",
    sheetId: "",
    _id: "",
    dataModel: [],
  },
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    reStoreAnalytics: (state) => {
      state.error = "";
      state.excelKeys = [];
      state.excelData = [];
      state.graphData = {
        position: "",
        sheetId: "",
        _id: "",
        dataModel: [],
      };
    },
    addTabItem: (state, action) => {
      state.tabItem = action.payload;
    },
    addGraphData: (state, action) => {
      state.graphData = action.payload;
    },
    addedGraphData: (state, action) => {
      state.isGraphDataAdd = action.payload;
    },
  },
  extraReducers: (builder) => {
    // create a sheet
    builder
      .addCase(createSheetAsync.pending, (state) => {
        state.postLoading = true;
        state.error = "";
      })
      .addCase(createSheetAsync.fulfilled, (state, action) => {
        state.postLoading = false;
        state.error = "";
      })
      .addCase(createSheetAsync.rejected, (state, action) => {
        state.postLoading = false;
        state.error = action.payload?.message || "Sheet create fail";
      });
    // update a sheet
    builder
      .addCase(updateSheetAsync.pending, (state) => {
        state.updateLoading = true;
        state.error = "";
      })
      .addCase(updateSheetAsync.fulfilled, (state, action) => {
        state.updateLoading = false;
        state.error = "";
      })
      .addCase(updateSheetAsync.rejected, (state, action) => {
        state.updateLoading = false;
        state.error = action.payload?.message || "Sheet create fail";
      });
    // excel upload data
    builder
      .addCase(uploadExcelAsync.pending, (state) => {
        state.excelPostLoading = true;
        state.error = "";
      })
      .addCase(uploadExcelAsync.fulfilled, (state) => {
        state.excelPostLoading = false;
        state.error = "";
      })
      .addCase(uploadExcelAsync.rejected, (state, action) => {
        state.excelPostLoading = false;
        state.error = action.payload?.message || "Sheet create fail";
      });
    // get all sheets
    builder
      .addCase(getSheetsAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getSheetsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.sheets = action.payload.getData;
      })
      .addCase(getSheetsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Fetch sheets fail";
      });
    // get analytics excel keys
    builder
      .addCase(getAnalyticsExcelKeyAsync.pending, (state) => {
        state.excelHeaderLoading = true;
        state.error = "";
      })
      .addCase(getAnalyticsExcelKeyAsync.fulfilled, (state, action) => {
        state.error = "";
        state.excelKeys = action.payload?.getData[0]?.key;
        state.excelHeaderLoading = false;
      })
      .addCase(getAnalyticsExcelKeyAsync.rejected, (state, action) => {
        state.excelHeaderLoading = false;
        state.error = action.payload?.message || "Fetch excel keys fail";
      });
    // get analytics excel data
    builder
      .addCase(getAnalyticsExcelDataAsync.pending, (state) => {
        state.excelDataLoading = true;
        state.error = "";
      })
      .addCase(getAnalyticsExcelDataAsync.fulfilled, (state, action) => {
        state.error = "";
        state.excelData = action.payload.getData;
        state.excelDataLoading = false;
      })
      .addCase(getAnalyticsExcelDataAsync.rejected, (state, action) => {
        state.excelDataLoading = false;
        state.error = action.payload?.message || "Fetch excel data fail";
      });
    // add analytics graph data
    builder
      .addCase(createAnalyticsGraphAsync.pending, (state) => {
        state.graphAddDataLoading = true;
        state.error = "";
      })
      .addCase(createAnalyticsGraphAsync.fulfilled, (state, action) => {
        state.error = "";
        state.graphAddDataLoading = false;
      })
      .addCase(createAnalyticsGraphAsync.rejected, (state, action) => {
        state.graphAddDataLoading = false;
        state.error = action.payload?.message || "Create graph data fail";
      });
    // update analytics graph data
    builder
      .addCase(updateAnalyticsGraphAsync.pending, (state) => {
        state.graphUpdateDataLoading = true;
        state.error = "";
      })
      .addCase(updateAnalyticsGraphAsync.fulfilled, (state, action) => {
        state.error = "";
        state.graphUpdateDataLoading = false;
      })
      .addCase(updateAnalyticsGraphAsync.rejected, (state, action) => {
        state.graphUpdateDataLoading = false;
        state.error = action.payload?.message || "Update graph data fail";
      });
    builder
      .addCase(getAllAnalyticsGraphAsync.pending, (state) => {
        state.graphDataLoading = true;
        state.error = "";
      })
      .addCase(getAllAnalyticsGraphAsync.fulfilled, (state, action) => {
        state.error = "";
        state.graphData = action.payload.getData?.[0] || [];
        state.graphDataLoading = false;
      })
      .addCase(getAllAnalyticsGraphAsync.rejected, (state, action) => {
        state.graphDataLoading = false;
        state.error = action.payload?.message || "Fetch graph data fail";
      });
    // delete graph data
    builder
      .addCase(deleteAnalyticsGraphAsync.pending, (state) => {
        state.deleteLoading = true;
        state.error = "";
      })
      .addCase(deleteAnalyticsGraphAsync.fulfilled, (state, action) => {
        state.error = "";
        state.deleteLoading = false;
      })
      .addCase(deleteAnalyticsGraphAsync.rejected, (state, action) => {
        state.deleteLoading = false;
        state.error = action.payload?.message || "Delete graph data fail";
      });
  },
});

export const { addTabItem, addGraphData, addedGraphData, reStoreAnalytics } =
  analyticsSlice.actions;
export default analyticsSlice;

import { lazy } from "react";
import { RouteType } from "../types";

const Welcome = lazy(() => import("../pages/Welcome"));
const Registration = lazy(() => import("../pages/Registration"));
const Login = lazy(() => import("../pages/Login"));
const NotFound = lazy(() => import("../pages/NotFound"));
const Dashboard = lazy(() => import("../pages/Admin/Dashboard"));
const Query = lazy(() => import("../pages/Admin/Query"));
const Input = lazy(() => import("../pages/Admin/Input"));
const RegistrationSuccess = lazy(() => import("../pages/RegistrationSuccess"));
const About = lazy(() => import("../pages/About"));
const FAQ = lazy(() => import("../pages/FAQ"));
const CFactorInstructions = lazy(() => import("../pages/CFactorInstructions"));

// auth routers
export const authRouters: RouteType[] = [
  {
    path: "dashboard",
    element: Dashboard,
    roles: ["agency", "organization"],
  },
  {
    path: "query",
    element: Query,
    roles: ["agency", "organization"],
  },
  {
    path: "input",
    element: Input,
    roles: ["agency"],
  },
];

// public routers
export const publicRouters: Omit<RouteType, "roles">[] = [
  {
    path: "welcome",
    element: Welcome,
  },
  {
    path: "about-us",
    element: About,
  },
  {
    path: "FAQ",
    element: FAQ,
    isRegister: true,
  },
  {
    path: "registration",
    element: Registration,
    isRegister: true,
  },
  {
    path: "registration-success",
    element: RegistrationSuccess,
    isRegister: true,
  },
  {
    path: "c-factor-instructions",
    element: CFactorInstructions,
    isRegister: true,
  },
  {
    path: "login",
    element: Login,
  },
  {
    path: "404",
    element: NotFound,
  },
  {
    path: "*",
    element: NotFound,
  },
];

import SidebarIcon from "../../../components/common/SidebarIcon";

export default function QueryIcon() {
  return (
    <SidebarIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.60835 16.4167C6.29169 15.6833 7.33335 15.7417 7.93335 16.5417L8.77502 17.6667C9.45002 18.5583 10.5417 18.5583 11.2167 17.6667L12.0584 16.5417C12.6584 15.7417 13.7 15.6833 14.3834 16.4167C15.8667 18 17.075 17.475 17.075 15.2583V5.86666C17.0834 2.50832 16.3 1.66666 13.15 1.66666H6.85002C3.70002 1.66666 2.91669 2.50832 2.91669 5.86666V15.25C2.91669 17.475 4.13335 17.9917 5.60835 16.4167Z"
          stroke="currentColor"
          strokeOpacity="0.8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66669 5.83334H13.3334"
          stroke="currentColor"
          strokeOpacity="0.8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 9.16666H12.5"
          stroke="currentColor"
          strokeOpacity="0.8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SidebarIcon>
  );
}

import { MenuProps } from "antd";
import { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SidebarProps } from "../layouts/SidebarLayout";
import { useAppSelector } from "../store/store";
import { stringToArray } from "../utils/array";

export default function withSidebar<T extends SidebarProps>(
  WrappedComponent: React.ComponentType<T>
) {
  return (props: Omit<T, keyof SidebarProps>) => {
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [openSidebar, setOpenSidebar] = useState(false);
    const [openMenuKeys, setOpenMenuKeys] = useState(["dashboard"]);

    const { innerWidth } = useAppSelector((state) => state.app);

    const handleOpenMenu: MenuProps["onOpenChange"] = (keys) => {
      setOpenMenuKeys(keys);
    };

    const handleCollapsed = () => {
      // const pathKey = stringToArray(location.pathname, "/")?.[1];

      // setOpenMenuKeys([pathKey]);
      setCollapsed(!collapsed);
    };

    const handleShowSidebar = () => {
      setOpenSidebar(true);
    };

    const handleCloseSidebar = () => {
      setOpenSidebar(false);
    };

    useLayoutEffect(() => {
      if (location.pathname) {
        const pathKey = stringToArray(location.pathname, "/")?.[1];

        setOpenMenuKeys([pathKey]);
      }
    }, [location.pathname]);

    useLayoutEffect(() => {
      window.addEventListener("resize", function () {
        if (innerWidth <= 991) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      });

      // window.addEventListener("resize", function () {
      //   if (innerWidth <= 1000 && innerWidth >= 767) {
      //     setCollapsed(true);
      //   } else {
      //     setCollapsed(false);
      //   }
      // });

      // if (innerWidth <= 1000 && innerWidth >= 767) {
      //   setCollapsed(true);
      // } else {
      //   setCollapsed(false);
      // }

      if (innerWidth <= 991) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }, [innerWidth]);

    const themeProps = {
      collapsed,
      isMobile,
      openSidebar,
      openMenuKeys,
      handleOpenMenu,
      handleCollapsed,
      handleShowSidebar,
      handleCloseSidebar,
    };

    return <WrappedComponent {...themeProps} {...(props as T)} />;
  };
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { APP_NAME } from "../../../helpers/config";
import {
  AuthUser,
  ErrorMessage,
  ILoginOthers,
  LoginData,
  PostRequest,
} from "../../../types/redux";

// login user
export const loginUserAsync = createAsyncThunk<
  AuthUser,
  PostRequest<LoginData, ILoginOthers>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "login/userLogin",
  async ({ data, others: { redirect, redirectUrl } }, { rejectWithValue }) => {
    try {
      const loginData = await AXIOS.post(BASE_API.login, data);

      // console.log(loginData?.data?.data?.role);

      if (loginData?.data?.token && loginData?.data?.data?.email) {
        AXIOS.defaults.headers.common["authtoken"] = loginData?.data?.token;

        localStorage.setItem(
          `${APP_NAME}-Login`,
          JSON.stringify(loginData?.data)
        );
      }

      redirect("/admin/dashboard");

      return loginData.data as AuthUser;
    } catch (err) {
      const errors = err as AxiosError;
      return rejectWithValue(errors.response?.data as ErrorMessage);
    }
  }
);

import { ConfigProvider } from "antd";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";
import "./styles/index.css";
import "./styles/override.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#F37020",
          colorPrimaryText: "#1E1B39",
          fontFamily: "Inter",
        },
        components: {
          Button: {
            colorPrimary: "#F37020",
            colorPrimaryHover: "#ffffff",
          },
        },
      }}
    >
      <App />
    </ConfigProvider>
  </Provider>
);

reportWebVitals();

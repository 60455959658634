import { createSlice } from "@reduxjs/toolkit";
import { QueryInitialState } from "../../../types/redux";
import {
  getPaginationDataGetRegistrationFormDataAsync,
  getRegistrationFormDataAsync,
} from "./queryAPI";

const initialState: QueryInitialState = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  error: "",
  queryData: null,
  queryCount: null,
};

const querySlice = createSlice({
  name: "query",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all query
    builder
      .addCase(getRegistrationFormDataAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getRegistrationFormDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.queryData = action.payload.getData;
      })
      .addCase(getRegistrationFormDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Get Query data fail";
      });
    // get all query count
    builder
      .addCase(
        getPaginationDataGetRegistrationFormDataAsync.pending,
        (state) => {
          state.postLoading = true;
          state.error = "";
        }
      )
      .addCase(
        getPaginationDataGetRegistrationFormDataAsync.fulfilled,
        (state, action) => {
          state.postLoading = false;
          state.error = "";
          state.queryCount = action.payload.getData;
        }
      )
      .addCase(
        getPaginationDataGetRegistrationFormDataAsync.rejected,
        (state, action) => {
          state.postLoading = false;
          state.error = action.payload?.message || "Get Query data fail";
        }
      );
  },
});

export default querySlice;

export const BASE_API = {
  // auth
  login: "/api/ennvators/api/v1/login",

  // University
  getAllUniversity: "/api/innavators/api/v1/university/get-all-university",

  // registration
  createRegistration: "/api/ennavators/api/v1/registration-form-fillup",

  // Admin
  getRegistrationFormData: "/api/ennavators/api/v1/get-registration-data",
  getPaginationDataGetRegistrationFormData:
    "/api/ennavators/api/v1/get-registration-pagination-data",

  // Input (Innavators C Factor)
  getAllInput: "/api/innavators/api/v1/input-data/getData",
  updateInput: "/api/innavators/api/v1/input-data/create-or-update",

  // Dashboard
  getUniversityPrecipitator:
    "/api/ennavators/api/v1/dashboard/universityrank-count",
  getAcademicYear: "/api/ennavators/api/v1/dashboard/academic-year-count",
  getTypeOfDegree: "/api/ennavators/api/v1/dashboard/type-of-degree-count",
  getSource: "/api/ennavators/api/v1/dashboard/source-count",
  getDegreeLevel: "/api/ennavators/api/v1/dashboard/degree-level-count",
  getGender: "/api/ennavators/api/v1/dashboard/gender-count",
  getTodayRegistration:
    "/api/ennavators/api/v1/get-registration-pagination-data?today=true",

  // analytics
  createSheet: "/api/sheet/v1/create-sheet",
  updateSheet: "/api/sheet/v1/update-single-sheet",
  getSheets: "/api/sheet/v1/get-sheets",

  // analytics data load
  createAnalyticsExcelKey: "/api/data-model/v1/create-key-model",
  createAnalyticsExcelData: "/api/data-model/v1/create-data-model",
  getAnalyticsExcelKey: "/api/data-model/v1/get-key",
  getAnalyticsExcelData: "/api/data-model/v1/get-model-data",

  // analytics graph data
  createAnalyticsGraph: "/api/graph/v1/create-graph",
  updateAnalyticsGraph: "/api/graph/v1/update-graph",
  getSingleAnalyticsGraph: "/api/graph/v1/get-single-graph",
  getAllAnalyticsGraph: "/api/graph/v1/get-all-data-under",
  deleteAnalyticsGraph: "/api/graph/v1/delete-graph",
};

export const AGENT_API = {};

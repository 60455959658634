import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import {
  ErrorMessage,
  GetResponse,
  Params,
  QueryCountData,
  QueryListData,
  RegistrationFormDataOthers,
  RegistrationFormDataParams,
} from "../../../types/redux";

// get getRegistrationFormData
export const getRegistrationFormDataAsync = createAsyncThunk<
  GetResponse<QueryListData[]>,
  Params<RegistrationFormDataParams, RegistrationFormDataOthers>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "query/getRegistrationFormData",
  async ({ params, others }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.post(
        BASE_API.getRegistrationFormData,
        others?.data,
        {
          params,
        }
      );
      // console.log(data.data);
      return data.data as GetResponse<QueryListData[]>;
    } catch (err) {
      const errors = err as AxiosError;
      const message = errors.response?.data as ErrorMessage;
      return rejectWithValue(message);
    }
  }
);

// get getPaginationDataGetRegistrationFormData
export const getPaginationDataGetRegistrationFormDataAsync = createAsyncThunk<
  GetResponse<QueryCountData>,
  Params<
    Omit<RegistrationFormDataParams, "skip" | "limit" | "sort">,
    RegistrationFormDataOthers
  >,
  {
    rejectValue: ErrorMessage;
  }
>(
  "query/getPaginationDataGetRegistrationFormData",
  async ({ params, others }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.post(
        BASE_API.getPaginationDataGetRegistrationFormData,
        others?.data,
        {
          params,
        }
      );

      // console.log(data.data);
      return data.data as GetResponse<QueryCountData>;
    } catch (err) {
      const errors = err as AxiosError;
      const message = errors.response?.data as ErrorMessage;
      return rejectWithValue(message);
    }
  }
);

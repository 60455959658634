import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import {
  CommonOthers,
  ErrorMessage,
  GetResponse,
  InputData,
  InputResponseData,
  Params,
  PostRequest,
} from "../../../types/redux";

// get all Input
export const getAllInputAsync = createAsyncThunk<
  GetResponse<InputResponseData[]>,
  Params<null, null>,
  {
    rejectValue: ErrorMessage;
  }
>("input/getAllInput", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getAllInput);
    return data.data as GetResponse<InputResponseData[]>;
  } catch (err) {
    const errors = err as AxiosError;
    const message = errors.response?.data as ErrorMessage;
    return rejectWithValue(message);
  }
});

// create a Input
export const createInputAsync = createAsyncThunk<
  null,
  PostRequest<InputData, CommonOthers>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "input/updateInput",
  async ({ data, others: { reRender, toastMessage } }, { rejectWithValue }) => {
    try {
      const createdData = await AXIOS.patch(BASE_API.updateInput, data);

      if (createdData.data) {
        toastMessage("success", "Input data added successfully");
        reRender(true);
      }

      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const message = errors.response?.data as ErrorMessage;
      return rejectWithValue(message);
    }
  }
);

export const formatPrice = (
  price: string | number = 0,
  locale = "en-US",
  currency = "USD"
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formatter.format(Number(price || 0));
};

export const AddZero = (num: number) => {
  if (num < 10 && num > -10) {
    return num < 10 && num >= 0 ? `0${num}` : `-0${Math.abs(num)}`;
  }

  return num;
};

export const isNumber = (value: any) => {
  return typeof value === "number" && isFinite(value);
};

export const percentage = (value: number, total: number) => {
  return (value / total) * 100;
};

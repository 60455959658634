import { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Loader from "./components/common/Loader";
import useApp from "./hooks/useApp";
import useAuthChecked from "./hooks/useAuthChecked";
import SidebarLayout from "./layouts/SidebarLayout";
import AuthMiddleware from "./middleware/AuthMiddleware";
import { authRouters, publicRouters } from "./routers/routers";
import { reStoreAnalytics } from "./store/features/analytics/analyticsSlice";
import { reStoreLogin } from "./store/features/auth/loginSlice";
import { useAppSelector } from "./store/store";

const App: React.FC = () => {
  useApp();
  const { authChecked } = useAuthChecked();
  const dispatch = useDispatch();
  const { isRegistration } = useAppSelector((state) => state.app);
  // const {
  //   login: { token, data },
  // } = useAppSelector((state) => state.login);

  useEffect(() => {
    dispatch(reStoreAnalytics());
    dispatch(reStoreLogin());
  }, [dispatch]);

  if (authChecked) {
    return <Loader />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={"/welcome"} />} />
        {/* {!isRegistration ? (
          <>
            <Route
              path="/registration"
              element={<Navigate to={"/welcome"} />}
            />
            <Route
              path="/registration-success"
              element={<Navigate to={"/welcome"} />}
            />
          </>
        ) : null} */}
        {/* public routers */}
        {publicRouters.map(({ path, element: Element, isRegister }, idx) => (
          <Route
            path={path}
            key={idx + path}
            element={
              isRegister && !isRegistration ? (
                <Navigate to={"/welcome"} />
              ) : (
                <Suspense fallback={<Loader />}>
                  <Element />
                </Suspense>
              )
            }
          />
        ))}

        {/* Auth routers */}
        <Route path={"admin"} element={<SidebarLayout />}>
          {authRouters.map(({ path, element: Element, roles }, idx) => (
            <Route
              path={path}
              key={idx + path}
              element={
                <AuthMiddleware roles={roles}>
                  <Suspense fallback={<Loader />}>
                    <Element />
                  </Suspense>
                </AuthMiddleware>
              }
            />
          ))}
        </Route>
      </Routes>
    </Router>
  );
};

export default App;

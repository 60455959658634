import SidebarIcon from "../../../components/common/SidebarIcon";

export default function InputIcon() {
  return (
    <SidebarIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.41669 6.3C7.67502 3.3 9.21669 2.075 12.5917 2.075H12.7C16.425 2.075 17.9167 3.56666 17.9167 7.29166V12.725C17.9167 16.45 16.425 17.9417 12.7 17.9417H12.5917C9.24169 17.9417 7.70002 16.7333 7.42502 13.7833"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.66669 10H12.4"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5417 7.20833L13.3334 10L10.5417 12.7917"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SidebarIcon>
  );
}

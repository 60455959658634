import { createSlice } from "@reduxjs/toolkit";
import { RegistrationInitialState } from "../../../types/redux";
import {
  createRegistrationAsync,
  getAllUniversityAsync,
} from "./registrationAPI";

const initialState: RegistrationInitialState = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  error: "",
  universityLoading: false,
  allUniversity: [],
};

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create a registration
    builder
      .addCase(createRegistrationAsync.pending, (state) => {
        state.postLoading = true;
        state.error = "";
      })
      .addCase(createRegistrationAsync.fulfilled, (state) => {
        state.postLoading = false;
        state.error = "";
      })
      .addCase(createRegistrationAsync.rejected, (state, action) => {
        state.postLoading = false;
        state.error = action.payload?.message || "Registration fail";
      });

    // get all university
    builder
      .addCase(getAllUniversityAsync.pending, (state) => {
        state.universityLoading = true;
        state.error = "";
      })
      .addCase(getAllUniversityAsync.fulfilled, (state, action) => {
        state.universityLoading = false;
        state.error = "";
        state.allUniversity = action.payload?.getData;
      })
      .addCase(getAllUniversityAsync.rejected, (state, action) => {
        state.universityLoading = false;
        state.error = action.payload?.message || "Registration fail";
      });
  },
});

export default registrationSlice;

import { createSlice } from "@reduxjs/toolkit";
import { InputInitialState } from "../../../types/redux";
import { createInputAsync, getAllInputAsync } from "./inputAPI";

const initialState: InputInitialState = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  error: "",
  inputs: null,
};

const inputSlice = createSlice({
  name: "input",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create a input
    builder
      .addCase(createInputAsync.pending, (state) => {
        state.updateLoading = true;
        state.error = "";
      })
      .addCase(createInputAsync.fulfilled, (state) => {
        state.updateLoading = false;
        state.error = "";
      })
      .addCase(createInputAsync.rejected, (state, action) => {
        state.updateLoading = false;
        state.error = action.payload?.message || "Input created fail";
      });

    // get all input
    builder
      .addCase(getAllInputAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getAllInputAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.inputs = action.payload?.getData[0];
      })
      .addCase(getAllInputAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Get Input fail";
      });
  },
});

export default inputSlice;

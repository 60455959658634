import { Image as AntImage } from "antd";
import { ImageProps } from "antd/es/image";

type Props = {
  width?: number | string;
  src: string;
  placeholder?: string;
  preview?: boolean;
} & ImageProps;

const Image: React.FC<Props> = ({
  src,
  placeholder = src,
  width = 200,
  preview = false,
  ...rest
}) => {
  return (
    <AntImage
      width={width}
      preview={preview}
      src={src}
      style={{ maxWidth: "100%", maxHeight: "100%" }}
      placeholder={<AntImage preview={false} src={placeholder} width={width} />}
      {...rest}
    />
  );
};

export default Image;

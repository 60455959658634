import {
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Layout, Menu, MenuProps, theme } from "antd";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import withSidebar from "../HOC/withSidebar";
import DashboardIcon from "../assets/icons/Sidebar/DashboardIcon";
import InputIcon from "../assets/icons/Sidebar/InputIcon";
import QueryIcon from "../assets/icons/Sidebar/QueryIcon";
import MainLogo from "../assets/images/logo.svg";
import Image from "../components/common/Image";
import ProfileMenu from "../components/common/ProfileMenu";
import { useAppSelector } from "../store/store";
import { activePathString, pathString } from "../utils/array";

const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

export type SidebarProps = {
  isMobile: boolean;
  collapsed: boolean;
  openSidebar: boolean;
  openMenuKeys: string[];
  handleOpenMenu: () => void;
  handleCollapsed: () => void;
  handleShowSidebar: () => void;
  handleCloseSidebar: () => void;
};

const SidebarLayout: React.FC<SidebarProps> = ({
  isMobile,
  collapsed,
  openSidebar,
  openMenuKeys,
  handleOpenMenu,
  handleCollapsed,
  handleShowSidebar,
  handleCloseSidebar,
}) => {
  let dynamicParams = useParams();
  const location = useLocation();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const {
    login: { data },
  } = useAppSelector((state) => state.login);

  const dynamicPath = Object.keys(dynamicParams || {})?.length;

  function getItem(
    label: React.ReactNode,
    path: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
    roles?: string[]
  ): MenuItem {
    return {
      key: pathString(path),
      icon,
      children,
      label: children?.length ? label : <Link to={path}>{label}</Link>,
      roles,
    } as MenuItem;
  }

  const SidebarAgencyMenuItems: MenuItem[] = [
    getItem("Dashboard", "/admin/dashboard", <DashboardIcon />),
    getItem("Queries", "/admin/query", <QueryIcon />),
    getItem("Input", "/admin/input", <InputIcon />),
  ];

  const SidebarOrganizationMenuItems: MenuItem[] = [
    getItem("Dashboard", "/admin/dashboard", <DashboardIcon />),
    getItem("Queries", "/admin/query", <QueryIcon />),
  ];

  return (
    <Layout className="min-h-screen">
      {isMobile ? (
        <Drawer
          placement="left"
          closable={false}
          onClose={handleCloseSidebar}
          open={openSidebar}
          key="left"
          contentWrapperStyle={{ width: "auto" }}
          bodyStyle={{ padding: 0 }}
        >
          <Sider
            trigger={null}
            theme="light"
            collapsible
            collapsed={false}
            className="!overflow-auto !h-screen custom-sidebar"
            width={250}
          >
            <div className="h-16 flex justify-center items-center">
              <Link to="/admin/dashboard">
                <Image width={30} src={MainLogo} alt={"APP_NAME"} />
              </Link>
            </div>

            <Menu
              theme="light"
              mode="inline"
              selectedKeys={[
                activePathString(
                  location.pathname,
                  "/",
                  dynamicPath ? true : false
                ),
              ]}
              openKeys={openMenuKeys}
              onOpenChange={handleOpenMenu}
              items={
                data?.role === "agency"
                  ? SidebarAgencyMenuItems
                  : data?.role === "organization"
                  ? SidebarOrganizationMenuItems
                  : ([] as MenuItem[])
              }
              className="sidebar-menu-item"
            />
          </Sider>
        </Drawer>
      ) : (
        <Sider
          trigger={null}
          theme="light"
          collapsible
          collapsed={collapsed}
          className={`!overflow-auto !h-screen !sticky top-0 custom-sidebar ${
            collapsed ? "toggle-sidebar" : ""
          }`}
          width={250}
        >
          <div
            className={`pt-5 pb-6 transition-all ${
              collapsed ? "text-center" : "pl-7"
            }`}
          >
            <Link to="/admin/dashboard">
              <Image width={30} src={MainLogo} alt={"APP_NAME"} />
            </Link>
          </div>

          <Menu
            theme="light"
            mode="inline"
            selectedKeys={[
              activePathString(
                location.pathname,
                "/",
                dynamicPath ? true : false
              ),
            ]}
            openKeys={openMenuKeys}
            onOpenChange={handleOpenMenu}
            items={
              data?.role === "agency"
                ? SidebarAgencyMenuItems
                : data?.role === "organization"
                ? SidebarOrganizationMenuItems
                : ([] as MenuItem[])
            }
            className="sidebar-menu-item"
          />
        </Sider>
      )}

      <Layout className="site-layout transition-all">
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <div className="flex items-center max-h-full justify-between pr-5">
            <div className="w-16 h-16 flex justify-center items-center">
              {isMobile ? (
                <Button
                  type="text"
                  icon={<MenuOutlined />}
                  onClick={handleShowSidebar}
                  className="flex justify-center items-center"
                />
              ) : (
                <Button
                  type="text"
                  icon={
                    collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                  }
                  onClick={handleCollapsed}
                  className="flex justify-center items-center"
                />
              )}
            </div>

            <ProfileMenu />
          </div>
        </Header>

        <Content
          style={{
            margin: 20,
            padding: "20px",
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default withSidebar(SidebarLayout);

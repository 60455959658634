import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import UserImage from "../../assets/images/user.svg";
import { userLogout } from "../../store/features/auth/loginSlice";
import { useAppDispatch } from "../../store/store";

export default function ProfileMenu() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    navigate("/login");
  };

  return (
    <Dropdown
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      className="cursor-pointer"
      rootClassName="profile-menu"
      dropdownRender={() => (
        <ul className="list-none bg-white shadow p-1 rounded">
          <li className="py-1.5 px-8 cursor-pointer select-none hover:bg-slate-50 text-sm rounded">
            Profile
          </li>
          <li
            onClick={() => dispatch(userLogout(handleLogout))}
            className="py-1.5 px-8 cursor-pointer select-none hover:bg-slate-50 text-sm rounded"
          >
            Logout
          </li>
        </ul>
      )}
    >
      <img className="w-10 h-10 rounded-full" src={UserImage} alt={"User"} />
    </Dropdown>
  );
}

import { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../store/store";
import { Role } from "../types";

type Props = {
  children: React.ReactNode;
  roles: Role[];
};

export default function AuthMiddleware({ children, roles }: Props) {
  const {
    login: { token, data },
  } = useAppSelector((state) => state.login);

  const accessRole = data?.role && roles?.length && roles?.includes(data.role);

  return token && data?.email ? (
    accessRole ? (
      <Fragment>{children}</Fragment>
    ) : (
      <Navigate to="/404" replace />
    )
  ) : (
    <Navigate to="/login" replace />
  );
}

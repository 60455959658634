import { Spin } from "antd";

type Props = {
  noTip?: boolean;
};

export default function Loader({ noTip }: Props) {
  return (
    <Spin
      className="w-full py-10"
      tip={noTip ? "" : "Loading..."}
      size="default"
    >
      <span />
    </Spin>
  );
}

import SidebarIcon from "../../../components/common/SidebarIcon";

export default function DashboardIcon() {
  return (
    <SidebarIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3333 7.09999V3.31666C18.3333 2.14166 17.8 1.66666 16.475 1.66666H13.1083C11.7833 1.66666 11.25 2.14166 11.25 3.31666V7.09166C11.25 8.27499 11.7833 8.74166 13.1083 8.74166H16.475C17.8 8.74999 18.3333 8.27499 18.3333 7.09999Z"
          stroke="currentColor"
          strokeOpacity="0.8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3333 16.475V13.1083C18.3333 11.7833 17.8 11.25 16.475 11.25H13.1083C11.7833 11.25 11.25 11.7833 11.25 13.1083V16.475C11.25 17.8 11.7833 18.3333 13.1083 18.3333H16.475C17.8 18.3333 18.3333 17.8 18.3333 16.475Z"
          stroke="currentColor"
          strokeOpacity="0.8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.75002 7.09999V3.31666C8.75002 2.14166 8.21669 1.66666 6.89169 1.66666H3.52502C2.20002 1.66666 1.66669 2.14166 1.66669 3.31666V7.09166C1.66669 8.27499 2.20002 8.74166 3.52502 8.74166H6.89169C8.21669 8.74999 8.75002 8.27499 8.75002 7.09999Z"
          stroke="currentColor"
          strokeOpacity="0.8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.75002 16.475V13.1083C8.75002 11.7833 8.21669 11.25 6.89169 11.25H3.52502C2.20002 11.25 1.66669 11.7833 1.66669 13.1083V16.475C1.66669 17.8 2.20002 18.3333 3.52502 18.3333H6.89169C8.21669 18.3333 8.75002 17.8 8.75002 16.475Z"
          stroke="currentColor"
          strokeOpacity="0.8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SidebarIcon>
  );
}

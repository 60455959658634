import { createSlice } from "@reduxjs/toolkit";
import { APP_NAME } from "../../../helpers/config";
import { AuthUser, LoginInitialState } from "../../../types/redux";
import { loginUserAsync } from "./loginAPI";

const initialState: LoginInitialState<AuthUser> = {
  loading: false,
  error: "",
  login: {
    data: null,
    token: "",
    message: "",
    status: "",
  },
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    reStoreLogin: (state) => {
      state.error = "";
    },
    userLoggedIn: (state, action) => {
      state.login = action.payload;
    },
    userLogout: (state, action) => {
      action.payload();
      localStorage.removeItem(`${APP_NAME}-Login`);
      state.login = {
        data: null,
        token: "",
        message: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    // login user
    builder
      .addCase(loginUserAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.login = action.payload;
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        localStorage.removeItem(`${APP_NAME}-Login`);

        state.loading = false;
        state.error = action?.payload?.message || "Login user fail";
      });
  },
});

export const { reStoreLogin, userLoggedIn, userLogout } = loginSlice.actions;

export default loginSlice;

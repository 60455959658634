import { createSlice } from "@reduxjs/toolkit";
import { DashboardInitialState } from "../../../types/redux";
import {
  getAcademicYearAsync,
  getDegreeLevelAsync,
  getGenderAsync,
  getSourceAsync,
  getTodayRegistrationAsync,
  getTypeOfDegreeAsync,
  getUniversityPrecipitatorAsync,
} from "./dashboardAPI";

const initialState: DashboardInitialState = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  error: "",
  universityPrecipitatorLoading: false,
  universityPrecipitator: null,
  academicYearLoading: false,
  academicYear: null,
  typeOfDegreeLoading: false,
  typeOfDegree: null,
  sourceLoading: false,
  source: null,
  degreeLevelLoading: false,
  degreeLevel: null,
  genderLoading: false,
  gender: null,
  todayRegistrationLoading: false,
  todayRegistration: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get University Precipitator
    builder
      .addCase(getUniversityPrecipitatorAsync.pending, (state) => {
        state.universityPrecipitatorLoading = true;
        state.error = "";
      })
      .addCase(getUniversityPrecipitatorAsync.fulfilled, (state, action) => {
        state.universityPrecipitatorLoading = false;
        state.error = "";
        state.universityPrecipitator = action.payload.getData;
      })
      .addCase(getUniversityPrecipitatorAsync.rejected, (state, action) => {
        state.universityPrecipitatorLoading = false;
        state.error =
          action.payload?.message || "Get University Precipitator fail";
      });
    // get academic Year
    builder
      .addCase(getAcademicYearAsync.pending, (state) => {
        state.academicYearLoading = true;
        state.error = "";
      })
      .addCase(getAcademicYearAsync.fulfilled, (state, action) => {
        state.academicYearLoading = false;
        state.error = "";
        state.academicYear = action.payload.getData;
      })
      .addCase(getAcademicYearAsync.rejected, (state, action) => {
        state.academicYearLoading = false;
        state.error = action.payload?.message || "Get academic year fail";
      });
    // get type Of Degree
    builder
      .addCase(getTypeOfDegreeAsync.pending, (state) => {
        state.typeOfDegreeLoading = true;
        state.error = "";
      })
      .addCase(getTypeOfDegreeAsync.fulfilled, (state, action) => {
        state.typeOfDegreeLoading = false;
        state.error = "";
        state.typeOfDegree = action.payload.getData;
      })
      .addCase(getTypeOfDegreeAsync.rejected, (state, action) => {
        state.typeOfDegreeLoading = false;
        state.error = action.payload?.message || "Get type of degree fail";
      });
    // get source
    builder
      .addCase(getSourceAsync.pending, (state) => {
        state.sourceLoading = true;
        state.error = "";
      })
      .addCase(getSourceAsync.fulfilled, (state, action) => {
        state.sourceLoading = false;
        state.error = "";
        state.source = action.payload.getData;
      })
      .addCase(getSourceAsync.rejected, (state, action) => {
        state.sourceLoading = false;
        state.error = action.payload?.message || "Get source fail";
      });
    // get degree Level
    builder
      .addCase(getDegreeLevelAsync.pending, (state) => {
        state.degreeLevelLoading = true;
        state.error = "";
      })
      .addCase(getDegreeLevelAsync.fulfilled, (state, action) => {
        state.degreeLevelLoading = false;
        state.error = "";
        state.degreeLevel = action.payload.getData;
      })
      .addCase(getDegreeLevelAsync.rejected, (state, action) => {
        state.degreeLevelLoading = false;
        state.error = action.payload?.message || "Get degree level fail";
      });
    // get gender
    builder
      .addCase(getGenderAsync.pending, (state) => {
        state.genderLoading = true;
        state.error = "";
      })
      .addCase(getGenderAsync.fulfilled, (state, action) => {
        state.genderLoading = false;
        state.error = "";
        state.gender = action.payload.getData;
      })
      .addCase(getGenderAsync.rejected, (state, action) => {
        state.genderLoading = false;
        state.error = action.payload?.message || "Get gender fail";
      });
    // get today registration
    builder
      .addCase(getTodayRegistrationAsync.pending, (state) => {
        state.todayRegistrationLoading = true;
        state.error = "";
      })
      .addCase(getTodayRegistrationAsync.fulfilled, (state, action) => {
        state.todayRegistrationLoading = false;
        state.error = "";
        state.todayRegistration = action.payload.getData;
      })
      .addCase(getTodayRegistrationAsync.rejected, (state, action) => {
        state.todayRegistrationLoading = false;
        state.error = action.payload?.message || "Get today registration fail";
        state.todayRegistration = null;
      });
  },
});

export default dashboardSlice;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import {
  AnalyticsExcelDataItems,
  AnalyticsExcelKeyItems,
  AnalyticsExcelKeyOthers,
  AnalyticsGraphDeleteParams,
  CommonOthers,
  ErrorMessage,
  GetAnalyticsExcelData,
  GetAnalyticsExcelOptions,
  GetResponse,
  GraphData,
  Params,
  PostRequest,
  SheetData,
  SheetItems,
  UpdateExcelOthers,
  UpdateGraphPrams,
  UpdateSheetOthers,
} from "../../../types/redux";
import { isNumber } from "../../../utils/number";

// create a sheet
export const createSheetAsync = createAsyncThunk<
  null,
  PostRequest<SheetData, CommonOthers>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "analytics/createSheet",
  async ({ data, others: { reRender, toastMessage } }, { rejectWithValue }) => {
    try {
      const createdData = await AXIOS.post(BASE_API.createSheet, data);

      if (createdData.data) {
        toastMessage("success", "Sheet created successfully");
        reRender(true);
      }

      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const message = errors.response?.data as ErrorMessage;
      return rejectWithValue(message);
    }
  }
);

// update a sheet
export const updateSheetAsync = createAsyncThunk<
  null,
  PostRequest<SheetData, UpdateSheetOthers>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "analytics/updateSheet",
  async (
    { data, others: { reRender, toastMessage, sheetId } },
    { rejectWithValue }
  ) => {
    try {
      const updateData = await AXIOS.patch(
        `${BASE_API.updateSheet}/${sheetId}`,
        data
      );

      if (updateData.data) {
        toastMessage("success", "Sheet updated successfully");
        reRender(true);
      }

      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const message = errors.response?.data as ErrorMessage;
      return rejectWithValue(message);
    }
  }
);

// upload excel
export const uploadExcelAsync = createAsyncThunk<
  null,
  PostRequest<any[], UpdateExcelOthers>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "analytics/uploadExcel",
  async (
    {
      data,
      others: {
        reRender,
        isUpdateCount,
        toastMessage,
        sheetId,
        useId,
        uploadedCount,
      },
    },
    { rejectWithValue }
  ) => {
    try {
      // const promises: Promise<any>[] = [];
      const uploadedKey = { sheetId, key: Object.keys(data?.[0] || {}) };

      const createdData = await AXIOS.post(
        BASE_API.createAnalyticsExcelKey,
        uploadedKey
      );

      if (!createdData) throw Error();

      // data?.forEach((el, idx) => {
      //   const valueList = Object.values(el || {});
      //   const field = Object.keys(el)?.map((item, index) => {
      //     const obj: {
      //       key: string;
      //       valueNumber?: number;
      //       valueString?: string;
      //     } = {
      //       key: item,
      //     };

      //     if (isNumber(valueList[index])) {
      //       obj.valueNumber = valueList[index] as number;
      //     } else {
      //       obj.valueString = valueList[index] as string;
      //     }

      //     return obj;
      //   });

      //   const item = {
      //     sheetId,
      //     field,
      //     serial: idx + 1,
      //   };

      //   const responsePromise = AXIOS.post(
      //     BASE_API.createAnalyticsExcelData,
      //     item
      //   )
      //     .then((item) => {
      //       if (isUpdateCount) {
      //         uploadedCount(item?.data?.serial, data?.length);
      //       }
      //     })
      //     .catch((err) => {
      //       // toastMessage("error", `${idx + 1} Row added fail!`);
      //     }) as any;

      //   promises.push(responsePromise);
      // });

      // await Promise.all(promises);

      // data?.forEach((el, idx) => {
      //   const valueList = Object.values(el || {});
      //   const field = Object.keys(el)?.map((item, index) => {
      //     const obj: {
      //       key: string;
      //       valueNumber?: number;
      //       valueString?: string;
      //     } = {
      //       key: item,
      //     };

      //     if (isNumber(valueList[index])) {
      //       obj.valueNumber = valueList[index] as number;
      //     } else {
      //       obj.valueString = valueList[index] as string;
      //     }

      //     return obj;
      //   });

      //   const item = {
      //     sheetId,
      //     field,
      //     serial: idx + 1,
      //   };

      //   const responsePromise = AXIOS.post(
      //     BASE_API.createAnalyticsExcelData,
      //     item
      //   )
      //     .then((item) => {
      //       if (isUpdateCount) {
      //         uploadedCount(item?.data?.serial, data?.length);
      //       }
      //     })
      //     .catch((err) => {
      //       // toastMessage("error", `${idx + 1} Row added fail!`);
      //     }) as any;

      //   promises.push(responsePromise);
      // });

      // await Promise.all(promises);

      const sheetData = data?.map((el, idx) => {
        const valueList = Object.values(el || {});

        const field = Object.keys(el)?.map((item, index) => {
          const obj: {
            key: string;
            valueNumber?: number;
            valueString?: string;
          } = {
            key: item,
          };

          if (isNumber(valueList[index])) {
            obj.valueNumber = valueList[index] as number;
          } else {
            obj.valueString = valueList[index] as string;
          }

          return obj;
        });

        return {
          field,
          sheetId,
          useId,
          serial: idx + 1,
        };
      });

      const response = await AXIOS.post(
        BASE_API.createAnalyticsExcelData,
        {
          field: sheetData?.slice(0, 1000),
        },
        {
          onUploadProgress: (progressEvent) => {
            return uploadedCount(
              progressEvent?.loaded,
              progressEvent?.total || 100
            );
          },
        }
      );

      if (response) {
        toastMessage("success", "Sheet created successfully");
        reRender(true);
      }

      return null;
    } catch (err) {
      console.log(err);
      const errors = err as AxiosError;
      const message = errors.response?.data as ErrorMessage;
      return rejectWithValue(message);
    }
  }
);

// get all sheets
export const getSheetsAsync = createAsyncThunk<
  GetResponse<SheetItems[]>,
  Params<null, null>,
  {
    rejectValue: ErrorMessage;
  }
>("analytics/getSheets", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getSheets);
    return data.data as GetResponse<SheetItems[]>;
  } catch (err) {
    const errors = err as AxiosError;
    const message = errors.response?.data as ErrorMessage;
    return rejectWithValue(message);
  }
});

// get analytics excel keys
export const getAnalyticsExcelKeyAsync = createAsyncThunk<
  GetResponse<AnalyticsExcelKeyItems[]>,
  Params<null, AnalyticsExcelKeyOthers>,
  {
    rejectValue: ErrorMessage;
  }
>("analytics/getAnalyticsExcelKey", async ({ others }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(
      `${BASE_API.getAnalyticsExcelKey}/${others?.sheetId}`
    );
    return data.data as GetResponse<AnalyticsExcelKeyItems[]>;
  } catch (err) {
    const errors = err as AxiosError;
    const message = errors.response?.data as ErrorMessage;
    return rejectWithValue(message);
  }
});

// get analytics excel data
export const getAnalyticsExcelDataAsync = createAsyncThunk<
  GetResponse<AnalyticsExcelDataItems[]>,
  PostRequest<GetAnalyticsExcelData, GetAnalyticsExcelOptions>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "analytics/getAnalyticsExcelData",
  async ({ data, others }, { rejectWithValue }) => {
    try {
      const responseData = await AXIOS.post(
        `${BASE_API.getAnalyticsExcelData}/${others.sheetId}`,
        data
      );
      return responseData.data as GetResponse<AnalyticsExcelDataItems[]>;
    } catch (err) {
      const errors = err as AxiosError;
      const message = errors.response?.data as ErrorMessage;
      return rejectWithValue(message);
    }
  }
);

// create single graph data
export const createAnalyticsGraphAsync = createAsyncThunk<
  null,
  PostRequest<GraphData, CommonOthers>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "analytics/createAnalyticsGraph",
  async ({ data, others: { reRender, toastMessage } }, { rejectWithValue }) => {
    try {
      const createdData = await AXIOS.post(BASE_API.createAnalyticsGraph, data);

      if (createdData.data) {
        toastMessage("success", "Graph data added successfully");
        reRender(false);
      }

      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const message = errors.response?.data as ErrorMessage;
      return rejectWithValue(message);
    }
  }
);

// update single graph data
export const updateAnalyticsGraphAsync = createAsyncThunk<
  null,
  PostRequest<GraphData, UpdateGraphPrams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "analytics/updateAnalyticsGraph",
  async (
    { data, others: { reRender, toastMessage, graphId } },
    { rejectWithValue }
  ) => {
    try {
      const createdData = await AXIOS.patch(
        `${BASE_API.updateAnalyticsGraph}/${graphId}`,
        data
      );

      if (createdData.data) {
        toastMessage("success", "Graph data updated successfully");
        reRender(true);
      }

      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const message = errors.response?.data as ErrorMessage;
      return rejectWithValue(message);
    }
  }
);

// get all graph data
export const getAllAnalyticsGraphAsync = createAsyncThunk<
  GetResponse<GraphData[]>,
  Params<null, AnalyticsExcelKeyOthers>,
  {
    rejectValue: ErrorMessage;
  }
>("analytics/getAllAnalyticsGraph", async ({ others }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(
      `${BASE_API.getAllAnalyticsGraph}/${others?.sheetId}`
    );

    return data.data as GetResponse<GraphData[]>;
  } catch (err) {
    const errors = err as AxiosError;
    const message = errors.response?.data as ErrorMessage;
    return rejectWithValue(message);
  }
});

// delete graph data
export const deleteAnalyticsGraphAsync = createAsyncThunk<
  null,
  Params<AnalyticsGraphDeleteParams, CommonOthers>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "analytics/deleteAnalyticsGraph",
  async ({ params, others }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.delete(
        `${BASE_API.deleteAnalyticsGraph}/${params?.modelId}/${params?.graphId}`
      );

      if (data?.data) {
        others?.reRender(true);
        others?.toastMessage("success", "Graph delete successfully");
      }

      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const message = errors.response?.data as ErrorMessage;
      return rejectWithValue(message);
    }
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import {
  CountData,
  ErrorMessage,
  GetResponse,
  Params,
  TodayCountData,
  UniversityPrecipitatorData,
} from "../../../types/redux";

// get University Precipitator
export const getUniversityPrecipitatorAsync = createAsyncThunk<
  GetResponse<UniversityPrecipitatorData[]>,
  Params<null, null>,
  {
    rejectValue: ErrorMessage;
  }
>("dashboard/getUniversityPrecipitator", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getUniversityPrecipitator);

    return data.data as GetResponse<UniversityPrecipitatorData[]>;
  } catch (err) {
    const errors = err as AxiosError;
    const message = errors.response?.data as ErrorMessage;
    return rejectWithValue(message);
  }
});

// get Academic Year
export const getAcademicYearAsync = createAsyncThunk<
  GetResponse<CountData[]>,
  Params<null, null>,
  {
    rejectValue: ErrorMessage;
  }
>("dashboard/getAcademicYear", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getAcademicYear);

    return data.data as GetResponse<CountData[]>;
  } catch (err) {
    const errors = err as AxiosError;
    const message = errors.response?.data as ErrorMessage;
    return rejectWithValue(message);
  }
});

// get Type Of Degree
export const getTypeOfDegreeAsync = createAsyncThunk<
  GetResponse<CountData[]>,
  Params<null, null>,
  {
    rejectValue: ErrorMessage;
  }
>("dashboard/getTypeOfDegree", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getTypeOfDegree);
    return data.data as GetResponse<CountData[]>;
  } catch (err) {
    const errors = err as AxiosError;
    const message = errors.response?.data as ErrorMessage;
    return rejectWithValue(message);
  }
});

// get Source
export const getSourceAsync = createAsyncThunk<
  GetResponse<CountData[]>,
  Params<null, null>,
  {
    rejectValue: ErrorMessage;
  }
>("dashboard/getSource", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getSource);
    return data.data as GetResponse<CountData[]>;
  } catch (err) {
    const errors = err as AxiosError;
    const message = errors.response?.data as ErrorMessage;
    return rejectWithValue(message);
  }
});

// get Degree Level
export const getDegreeLevelAsync = createAsyncThunk<
  GetResponse<CountData[]>,
  Params<null, null>,
  {
    rejectValue: ErrorMessage;
  }
>("dashboard/getDegreeLevel", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getDegreeLevel);
    return data.data as GetResponse<CountData[]>;
  } catch (err) {
    const errors = err as AxiosError;
    const message = errors.response?.data as ErrorMessage;
    return rejectWithValue(message);
  }
});

// get Gender
export const getGenderAsync = createAsyncThunk<
  GetResponse<CountData[]>,
  Params<null, null>,
  {
    rejectValue: ErrorMessage;
  }
>("dashboard/getGender", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getGender);
    return data.data as GetResponse<CountData[]>;
  } catch (err) {
    const errors = err as AxiosError;
    const message = errors.response?.data as ErrorMessage;
    return rejectWithValue(message);
  }
});

// get today registration
export const getTodayRegistrationAsync = createAsyncThunk<
  GetResponse<TodayCountData>,
  Params<null, null>,
  {
    rejectValue: ErrorMessage;
  }
>("dashboard/getTodayRegistration", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.post(BASE_API.getTodayRegistration);
    return data.data as GetResponse<TodayCountData>;
  } catch (err) {
    const errors = err as AxiosError;
    const message = errors.response?.data as ErrorMessage;
    return rejectWithValue(message);
  }
});

import React from "react";

export default function SidebarIcon({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <span
      role="img"
      aria-label="appstore"
      className="anticon anticon-appstore ant-menu-item-icon -!ml-1"
    >
      {children}
    </span>
  );
}

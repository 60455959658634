import { useLayoutEffect, useState } from "react";
import AXIOS from "../helpers/api";
import { APP_NAME } from "../helpers/config";
import { userLoggedIn } from "../store/features/auth/loginSlice";
import { useAppDispatch, useAppSelector } from "../store/store";

export default function useAuthChecked() {
  const dispatch = useAppDispatch();
  const [authChecked, setAuthChecked] = useState(true);
  const {
    login: { token },
  } = useAppSelector((state) => state.login);

  if (token) {
    AXIOS.defaults.headers.common["authtoken"] = token;
  }

  useLayoutEffect(() => {
    const localAuth = localStorage?.getItem(`${APP_NAME}-Login`);

    if (localAuth) {
      const auth = JSON.parse(localAuth);

      if (auth?.token) {
        dispatch(userLoggedIn(auth));
      } else {
        localStorage.removeItem(`${APP_NAME}-Login`);
        dispatch(
          userLoggedIn({
            name: "",
            email: "",
            role: null,
            profilePicture: null,
            token: null,
            agentId: "",
          })
        );
      }
    }

    setAuthChecked(false);
  }, [dispatch, setAuthChecked]);

  return {
    authChecked,
  };
}
